
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2"
import axios from 'axios';


const Dashboard = () => {

    // statistics
    const [totalUsers, setTotalUsers] = useState(0);
    const [newDayUsers, setNewDayUsers] = useState(0);
    const [newWeekUsers, setNewWeekUsers] = useState(0);
    const [newMonthUsers, setNewMonthUsers] = useState(0);
    const [totalRequests, setTotalRequests] = useState(0);
    const [requestsSize, setRequestsSize] = useState(0);

    const [file, setFile] = useState();

 
    const { jwtToken } = useOutletContext();
    const { userRole } = useOutletContext();


    const { setAlertClassName } = useOutletContext();
    const { setAlertMessage } = useOutletContext();



    const navigate = useNavigate();

    // delete all the previous errors
    useEffect(() => {
        setAlertClassName("d-none"); // hide alert if it was
        setAlertMessage("");
    }, [setAlertClassName, setAlertMessage]);

    useEffect(() => {

        if (jwtToken === "" || userRole !== "admin") {
            navigate("/login");
            return
        }

        // headers for request
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwtToken);

        // options for request
        const requestOptions = {
            method: "GET",
            headers: headers,
            credentials: "include"
        }
        // request to backend
        fetch(`${process.env.REACT_APP_BACKEND}/admin/dashboard`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setAlertClassName("alert-danger"); // show error class
                    setAlertMessage(data.message);
                    return
                } else {
                    setAlertClassName("d-none"); // hide alert if it was
                    setAlertMessage("");
                    setTotalUsers(data.total_users);
                    setNewDayUsers(data.day_users);
                    setNewWeekUsers(data.week_users);
                    setNewMonthUsers(data.month_users);
                    setTotalRequests(data.total_requests);
                    setRequestsSize(data.requests_size);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [jwtToken, navigate, setAlertClassName, setAlertMessage, userRole]);


    function handleChange(event) {
        setFile(event.target.files[0])
    }

    function uploadMediaDB(event) {

        event.preventDefault()
        const url = `${process.env.REACT_APP_BACKEND}/admin/upload-media-db`;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + jwtToken,
            },
        };
        axios.post(url, formData, config)
            .then((response) => {
                if (response.data.error) {
                    setAlertClassName("alert-danger"); // show error class
                    setAlertMessage(response.data.message);
                    return
                } else {
                    setAlertClassName("d-none"); // hide alert
                    setAlertMessage("");
                    Swal.fire(
                        'Uploaded!',
                        'New media list has been uploaded.',
                        'success'
                    )
                }
            })
            .catch(err => {
                console.log(err);
            })
    }


    return (
        <div>
            {userRole === "admin" &&
                <>
                    <h2>Admin Dashboard</h2>

                    <hr />
                    <h2>Users</h2>
                    <div className="row mt-2">

                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Total users</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">Number of all registered users in the app</h6>
                                    <h2 className="card-text">{totalUsers}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">New day users</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">New registered users for the last 24 hours</h6>
                                    <h2 className="card-text">{newDayUsers}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">New week users</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">New registered users for the last week</h6>
                                    <h2 className="card-text">{newWeekUsers}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">New month users</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">New registered users for the last month</h6>
                                    <h2 className="card-text">{newMonthUsers}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className="mt-3">Requests</h2>
                    <div className="row mt-2">

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Total requests</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">Number of all requests in the storage</h6>
                                    <h2 className="card-text">{totalRequests}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Requests size</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">Size of all requests in storage</h6>
                                    <h2 className="card-text">{requestsSize} GB</h2>
                                </div>
                            </div>
                        </div>

                    </div>

                    < hr />

                    <div className="mt-3">
                        <form onSubmit={uploadMediaDB} method="POST">
                            <label htmlFor="formFile" className="form-label"><h3>Upload new media DB</h3></label>
                            <div className="row">
                                <div className="col-auto">
                                    <input className="form-control" type="file" onChange={handleChange} required/>
                                </div>
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-primary">Upload</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </>
            }
        </div>
    )
}

export default Dashboard;