import { useEffect, useState } from "react";
import Input from "./form/input";
import { useNavigate, useOutletContext } from "react-router-dom";
import jwt_decode from 'jwt-decode';

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // functions from App component
    // to transit values of JWT and errors to App component
    const { setJwtToken } = useOutletContext();
    const { setAlertClassName } = useOutletContext();
    const { setAlertMessage } = useOutletContext();
    const { toggleRefresh } = useOutletContext();
    const { setUserRole } = useOutletContext();

    const navigate = useNavigate();

    // delete all the previous errors
    useEffect(() => {
        setAlertClassName("d-none"); // hide alert if it was
        setAlertMessage("");
    }, [setAlertClassName, setAlertMessage]);


    // Log In
    const handleSubmit = (event) => {

        event.preventDefault();

        // Validation 
        // check if the fields are not empty
        if (password === "" || email === "") {
            setAlertClassName("alert-danger");
            setAlertMessage("All fields must be filled!")
            return
        }

        // check if password is too long
        if (password.length > 50) {
            setAlertClassName("alert-danger");
            setAlertMessage("Password is too long!");
            return
        }

        // check if password is too weak
        if (password.length < 3) {
            setAlertClassName("alert-danger");
            setAlertMessage("Password is extremely weak!");
            return
        }

        //  create the request payload
        //  email and password are from state
        let payload = {
            email: email,
            password: password,
        }

        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            withCredentials: true,
            body: JSON.stringify(payload),
        }

        // make a request
        fetch(`${process.env.REACT_APP_BACKEND}/admin/authenticate`, requestOptions)
            // convert response to json 
            .then((response) => response.json())
            .then((data) => {
                // if there IS an error 
                if (data.error) {
                    setAlertClassName("alert-danger"); // show error class
                    setAlertMessage(data.message);

                } else { // if there is NO error
                    setJwtToken(data.access_token);// set access token
                    var decoded = jwt_decode(data.access_token); // decode jwt token and define user's role
                    setUserRole(decoded.rol); // set user's role
                    setAlertClassName("d-none"); // hide alert if it was
                    setAlertMessage("");
                    toggleRefresh(true); // start timer for refreshing jwt
                    navigate("/"); // redirect to dashboard page
                }
            })
            // if there is an unexpected error
            .catch(error => {
                setAlertClassName("alert-danger");
                setAlertMessage(error);
            })
    }

    return (
        <div className="col-md-6 offset-md-3">
            <h2>Authentication</h2>
            <hr />
            <form onSubmit={handleSubmit}>
                <Input
                    title="Email Address"
                    type="email"
                    className="form-control"
                    name="email"
                    autoComplete="email-new"
                    onChange={(event) => setEmail(event.target.value)}
                />
                <Input
                    title="Password"
                    type="password"
                    className="form-control"
                    name="password"
                    autoComplete="email-new"
                    onChange={(event) => setPassword(event.target.value)}
                />
                <hr />
                <div className="row">
                    <div className="col-2">
                        <input
                            type="submit"
                            className="btn btn-primary"
                            value="Log In"
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Login;