import { useCallback, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Alert from './components/Alert';
import jwt_decode from 'jwt-decode';

function App() {

  const [jwtToken, setJwtToken] = useState(""); // storage for jwt token
  const [userRole, setUserRole] = useState(""); // storage for jwt token
  const [alertMessage, setAlertMessage] = useState(""); //storage for alert message
  const [alertClassName, setAlertClassName] = useState("d-none");

  const [tickInterval, setTickInterval] = useState(); // storage for refresh token interval

  const navigate = useNavigate();

  //  ticker to refresh token-----------------------------------------
  // this func is passed to useEffect refresh function
  const toggleRefresh = useCallback((status) => {
    console.log("refresh token timer is starting ...");

    // if status is true, turn on timer
    if (status) {
      let i = setInterval(() => {
        // create request options
        const requestOptions = {
          method: "GET",
          credentials: "include",
        }
        // send request with options
        fetch(`${process.env.REACT_APP_BACKEND}/admin/refresh`, requestOptions)
          // convert response to json
          .then((response) => response.json())
          // if there is token in response, set it
          .then((data) => {
            if (data.access_token) {
              setJwtToken(data.access_token);
            }
          })
          .catch(error => {
            console.log("user is not logged in");
          })
      }, 540000); // set interval to 9 minutes
      setTickInterval(i);
    } else {
      setTickInterval(null);
      clearInterval(tickInterval);
    }
  }, [tickInterval])

  // LogOut user 
  const logOut = () => {
    // create request options
    const requestOptions = {
      method: "GET",
      credentials: "include",
    }
    // send request with options
    fetch(`${process.env.REACT_APP_BACKEND}/admin/logout`, requestOptions)
      .catch(error => {
        console.log("error logging out", error);
      })
      // executes anyway
      .finally((() => {
        setJwtToken("");
        toggleRefresh(false); // stop refreshing jwt
      }))
    navigate("/login");
  }

  //  Refresh jwtToken and activates auto refreshing 
  useEffect(() => {
    // create request options
    if (jwtToken === "" || userRole !== "admin") {
      const requestOptions = {
        method: "GET",
        credentials: "include",
      }
      // send request with options
      fetch(`${process.env.REACT_APP_BACKEND}/admin/refresh`, requestOptions)
        // convert response to json
        .then((response) => response.json())
        // if there is token in response, set it
        .then((data) => {
          if (data.access_token) {
            setJwtToken(data.access_token);// set access token
            var decoded = jwt_decode(data.access_token); // decode jwt token and define user's role
            setUserRole(decoded.rol); // set user's role
            setAlertClassName("d-none"); // hide alert if it was
            setAlertMessage("");
            toggleRefresh(true); // start timer for refreshing jwt
            navigate("/"); // redirect to dashboard page
          }
        })
        .catch(error => {
          console.log("admin is not logged in", error);
        })
    }
  }, [jwtToken, toggleRefresh, navigate, userRole])


  return (
    <div className="container">
      <div className="row">

        <div className="col-6">
          <h1 className="display-4">Admin</h1>
        </div>

        <div className="col-6 mt-3 text-end">
          {jwtToken === ""
            ? <Link to="/login"><h5><span className="badge bg-success">Log In</span></h5></Link>
            : <a href='#!' onClick={logOut}><h5><span className='badge bg-danger'>Log Out</span></h5></a>
          }
        </div>

        <hr className="mb-3"></hr>
      </div>

      <div className="row">
        <div className="col-md-2">
          <nav className="list-group">
            <Link to="/" className="list-group-item list-group-item-action">Dashboard</Link>

          </nav>
        </div>
        <div className="col-md-10">
          <Alert
            message={alertMessage}
            className={alertClassName}
          />
          {/* give access to this functions in components*/}
          <Outlet context={{
            jwtToken,
            userRole,
            setJwtToken,
            setAlertClassName,
            setAlertMessage,
            toggleRefresh,
            setUserRole
          }} />
        </div>
      </div>
    </div>
  );
}

export default App;
